import NewFeatureEntity from "../../../../../domain/entities/NewFeatureEntity";
import UserEntity from "../../../../../domain/entities/UserEntity";
import { DateOperations } from "../../../../../ui/utils/DateOperations";
import TextOperations from "../../../../../ui/utils/TextOperations";


const _toShowFeatures: NewFeatureEntity[] = [
    {
        version: 1,
        image: '/assets/new_releases/bulk_import.jpg', //bulk import
        onClickUrl: '/new_features',
        roles: ['senior_agent', 'chief_'],
    },
    {
        version: 1,
        image: '/assets/new_releases/editing_shifts.jpg',
        onClickUrl: undefined,
        roles: ['chief_', 'supervisor', 'senior_agent', 'manager', 'senior_manager_'],
    },
    {
        version: 1,
        image: '/assets/new_releases/enhacing.jpg',
        onClickUrl: undefined,
        roles: ['Service_Desk_Agent', 'Service_Desk_Supervisor'],
    },
];

const toShowFeatures: NewFeatureEntity[] = _toShowFeatures.map((feature: NewFeatureEntity) => {
    return {
        ...feature,
        roles: feature.roles.map((role) => role.toLowerCase().replaceAll('_', ' ')),
    };
});

const _getLastestVersion = () => {
    const APP_CACHE_KEY_DELETER = 'app_cache_key_deleter';
    const lastestVersion = parseInt(localStorage.getItem(APP_CACHE_KEY_DELETER) || '0');
    return lastestVersion;
}

const ShouldShowNewFeaturesApiImpl = async (keySaved: string, user: UserEntity): Promise<NewFeatureEntity[]> => {
    //keySaved is {email: string, lastVersionSeen: number, whenRemember: date | never}
    try {
        const lastestVersion = _getLastestVersion();
        
        const checks = localStorage.getItem(keySaved); //is null or next date to show
        console.log('user new features', {user,checks, toShowFeatures});
        if (checks == null) {
            return toShowFeatures.filter((feature) => feature.version >= lastestVersion && feature.roles.includes(user.role?.name.toLocaleLowerCase() || ''));
        }
        let parsedList: { email: string, lastVersionSeen: number, whenRemember: string }[] = [];
        try{
            parsedList = JSON.parse(checks);
        }catch(e){}
        //search the user email and check the last version to seen
        const userCheck = parsedList.find((check) => check.email == user.email);

        if (userCheck == undefined) {
            return toShowFeatures.filter((feature) => feature.version >= lastestVersion && feature.roles.includes(user.role?.name.toLocaleLowerCase() || ''));
        } else if (userCheck.lastVersionSeen == lastestVersion && userCheck.whenRemember == 'never') {
            return [];
        } else {
            //can show all features
            const nextDate = new Date(userCheck.whenRemember);
            const rememberTomorrow = DateOperations.isAfterToday(nextDate);
            if (rememberTomorrow) return [];
            return toShowFeatures.filter((feature) => feature.version >= userCheck.lastVersionSeen && feature.roles.includes(user.role?.name.toLocaleLowerCase() || ''));
        }
    } catch (e) {
        return [];
    }
};

export default ShouldShowNewFeaturesApiImpl;
import { injectable } from "inversify";
import NewFeaturesRepository from "../../../domain/repositories/NewFeaturesRepository";
import ShouldShowNewFeaturesApiImpl from "./api/impl/ShouldShowNewFeaturesApiImpl";
import DontShowAgainNewFeaturesApiImpl from "./api/impl/DontShowAgainNewFeaturesApiImpl";
import ShowLaterNewFeaturesApiImpl from "./api/impl/ShowLaterNewFeaturesApiImpl";
import UserEntity from "../../../domain/entities/UserEntity";
import NewFeatureEntity from "../../../domain/entities/NewFeatureEntity";

@injectable()
export default class NewFeaturesRepositoryFake implements NewFeaturesRepository {
    keyToStore:string = "newFeatures";
    featuresToShow = (user: UserEntity): Promise<NewFeatureEntity[]> => ShouldShowNewFeaturesApiImpl(this.keyToStore, user);
    dontShowAgain = (user: UserEntity): Promise<void> => DontShowAgainNewFeaturesApiImpl(this.keyToStore, user);
    showLater = (user: UserEntity): Promise<void> => ShowLaterNewFeaturesApiImpl(this.keyToStore, user);
}
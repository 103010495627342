import { injectable } from "inversify";
import UserProvider from "../../providers/user/UserProvider";
import OffBoardingRepository, { SearchUserInfoResponse, StartTerminationApiSearchUserInfoField } from "../../repositories/OffBoardingRepository";
import { Either, left } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";

interface _props {
    offBoardingRepository: OffBoardingRepository;
    userProvider: UserProvider;
}

@injectable()
export default class SearchEmployeeForOffBoardingUseCase {
    offBoardingRepository: OffBoardingRepository;
    userProvider: UserProvider;

    constructor({ offBoardingRepository, userProvider }: _props) {
        this.offBoardingRepository = offBoardingRepository;
        this.userProvider = userProvider;
    }

    public call = async (field: StartTerminationApiSearchUserInfoField, val: string ): Promise<Either<ExceptionEntity, SearchUserInfoResponse>> => {
        if(this.userProvider.Actions.user?.permissions?.prc.create !== true) return left({code: 'UserNotAuthorized'});
        const response = await this.offBoardingRepository.searchUserInfo(field, val);
        return response;
    }
}

export const SearchEmployeeForOffBoardingUseCaseName = 'SearchEmployeeForOffBoardingUseCase';
import { injectable } from "inversify";
import NewFeaturesRepository from "../../repositories/NewFeaturesRepository";
import ModalsProvider from "../../providers/modal/ModalsProvider";
import { FC } from "react";
import UserProvider from "../../providers/user/UserProvider";
import NewFeatureEntity from "../../entities/NewFeatureEntity";

interface props {
    newFeaturesRepository: NewFeaturesRepository;
    userProvider: UserProvider;
}
@injectable()
export default class ShouldShowNewFeaturesUseCase {
    newFeaturesRepository: NewFeaturesRepository;
    userProvider: UserProvider;

    constructor(props: props) {
        this.newFeaturesRepository = props.newFeaturesRepository;
        this.userProvider = props.userProvider;
    }

    public call = async ( ): Promise<NewFeatureEntity[]> => {
        const currentUser = this.userProvider.Actions.user;
        if (!currentUser) return [];
        const featuresToShow = await this.newFeaturesRepository.featuresToShow(currentUser);
        console.log('getted new featuresss', featuresToShow)
        return featuresToShow;
    }    
}

export const ShouldShowNewFeaturesUseCaseName = "ShouldShowNewFeaturesUseCase";
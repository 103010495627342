import { isRight } from "fp-ts/lib/Either";
import di from "../../../../../di/DependencyInjection";
import ModuleEntity from "../../../../../domain/entities/ModuleEntity";
import ModulePermision from "../../../../../domain/entities/ModulePermision";
import PermisionListEntity, { PermissionsEntity } from "../../../../../domain/entities/PermisionListEntity";
import UserEntity from "../../../../../domain/entities/UserEntity";
import GetAllRolesUseCase, { GetAllRolesUseCaseName } from "../../../../../domain/use_cases/roles/GetAllRolesUseCase";

const _search = async () => await di.get<GetAllRolesUseCase>(GetAllRolesUseCaseName).call(false);

const SetPermissionOfUserApiImpl = async (user: UserEntity): Promise<PermisionListEntity | undefined> => {
    const responseRoles = await _search();

    if (isRight(responseRoles)) {
        const roleOfUser = responseRoles.right.find((role) => role.id == user.role_id);
        const allPermisionsOfRole: ModulePermision[] = roleOfUser?.modules?.reduce((acc: ModulePermision[], module: ModuleEntity) => acc.concat(module.permissions), []) ?? [];
        const parseModulePermissionToPermissionsEntity = (permisionName: string): PermissionsEntity => {
            const modulePermission = allPermisionsOfRole.find((modulePermission) => modulePermission.id == permisionName);
            return {
                create: modulePermission?.canCreate == true,
                read: modulePermission?.canView == true,
                update: modulePermission?.canEdit == true,
                delete: modulePermission?.canDelete == true,
                assign: modulePermission?.canAssign == true,
            }
        }
        //add permisions to user 
        const borned = {
            create: true,
            read: true,
            update: true,
            delete: true,
            assign: true,
        }
        const parsePermisionName: PermisionListEntity = {
            calendar: {
                my_calendar: {
                    create: false,
                    read: parseModulePermissionToPermissionsEntity('Schedule').read,
                    update: false,
                    delete: false,
                    assign: false,
                },
                others_calendar: {
                    create: parseModulePermissionToPermissionsEntity('Schedule').create,
                    read: parseModulePermissionToPermissionsEntity('Schedule').create,
                    update: parseModulePermissionToPermissionsEntity('Schedule').create,
                    delete: parseModulePermissionToPermissionsEntity('Schedule').create,
                    assign: parseModulePermissionToPermissionsEntity('Schedule').create,
                },
            },
            prc: borned,
            shift: {
                calendar: {
                    create: parseModulePermissionToPermissionsEntity('Schedule').create,
                    read: parseModulePermissionToPermissionsEntity('Schedule').create,
                    update: parseModulePermissionToPermissionsEntity('Schedule').create,
                    delete: parseModulePermissionToPermissionsEntity('Schedule').create,
                    assign: parseModulePermissionToPermissionsEntity('Schedule').create,
                },
                asign: {
                    create: parseModulePermissionToPermissionsEntity('Schedule').create,
                    read: parseModulePermissionToPermissionsEntity('Schedule').create,
                    update: parseModulePermissionToPermissionsEntity('Schedule').create,
                    delete: parseModulePermissionToPermissionsEntity('Schedule').create,
                    assign: parseModulePermissionToPermissionsEntity('Schedule').create,
                },
            },
            schedule: parseModulePermissionToPermissionsEntity('Schedule'),
            role: parseModulePermissionToPermissionsEntity('Role'),
            employee: parseModulePermissionToPermissionsEntity('Employee'),
            shiftTemplate: parseModulePermissionToPermissionsEntity('Shifttemplate'),
            timeTracking: parseModulePermissionToPermissionsEntity('Timetracking'),
            user: parseModulePermissionToPermissionsEntity('User'),
            policy: parseModulePermissionToPermissionsEntity('Policy'),
            permission: parseModulePermissionToPermissionsEntity('Permission'),
            resource: parseModulePermissionToPermissionsEntity('Resource'),
            attendance: parseModulePermissionToPermissionsEntity('Attendance'),
            shiftSwap: parseModulePermissionToPermissionsEntity('Shiftswap'),
            aiTranscription: parseModulePermissionToPermissionsEntity('Transcription'),
            aiDashboard: parseModulePermissionToPermissionsEntity('Dashboard'),
            aiEvaluation: parseModulePermissionToPermissionsEntity('Evaluation'),
        }
        user.role = { name: roleOfUser?.name ?? '' };

        return parsePermisionName;

    } else {
        return undefined;
    }

}

export default SetPermissionOfUserApiImpl;
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../entities/ExceptionEntity";
import UserEntity from "../entities/UserEntity";

export interface SearchUserInfoResponse {
    name: string;
    hrmId: string;
    language: string;
    email: string;
    gender: string;
    image: string;
    birthdate: Date;
    startDate: Date;
    personalEmail: string;
    employeeSupervisor: UserEntity;
    department: string;
    workLocation: string;
    location: string;
    positionAtMomentOfTermination: string;
}
export enum StartTerminationApiSearchUserInfoField { EMAIL, HRM_ID, NAME }
export default interface OffBoardingRepository {
    searchUserInfo: (field: StartTerminationApiSearchUserInfoField, val: string ) => Promise<Either<ExceptionEntity, SearchUserInfoResponse>>;
    startTermination: (noEditable: SearchUserInfoResponse, info: {
        elegibleForRehire: boolean;
        companyPropertyReturned: string;
        companyPropertyNotReturned?: string;
        terminationPhase: string;
        terminationType: string;
        voluntaryTerminationReason?: string;
        jobDissatisfactionReason?: string;
        newOtherJobDetailedReason?: string;
        involuntaryTerminationReason?: string;
        supportingComment?: string;
        resignationFiles: File[];
    }) => Promise<Either<ExceptionEntity, void>>;
}

export const OffBoardingRepositoryName = "OffBoardingRepository";
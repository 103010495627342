import Constants, { ConstantsName } from "../../../../../constants/Constants";
import di from "../../../../../di/DependencyInjection";
import BusinessUnitEntity from "../../../../../domain/entities/BusinessUnitEntity";
import GroupEntity from "../../../../../domain/entities/GroupEntity";
import SearchResultEntity from "../../../../../domain/entities/SearchResultEntity";
import ShiftEntity from "../../../../../domain/entities/ShiftEntity";
import UserEntity from "../../../../../domain/entities/UserEntity";
import MastersProvider, { MastersProviderName } from "../../../../../domain/providers/master/MastersProvider";
import { GetUsersWithShiftResponse } from "../../../../../domain/repositories/ShiftRepository";
import DateParse from "../../../../../ui/utils/DateParse";
import ShiftHostDto from "../../../../dto/impl/ShiftHostDto";
import UserHostDto from "../../../../dto/impl/UserHostDto";
import Host2Api, { Host2ApiName } from "../../../../settings/Host2Api";


//TODO implement
const SearchUsersWithShiftApiImpl = async (businessUnit: string[], supervisors: string[], employees: string[], groups: string[], startDate: Date, endDate: Date, page: number, itemsPerPage: number): Promise<SearchResultEntity<GetUsersWithShiftResponse>> => {
        //params are userid
        let params = "";

        if (groups.length > 0) {
                params = groups.map((group) => 'groups=' + parseInt(group)).join('&');
        } else if (employees.length > 0) {
                params = employees.map((employee) => 'userid=' + parseInt(employee)).join('&');
        } else if (supervisors.length > 0) {
                params = supervisors.map((supervisor) => 'supervisorid=' + parseInt(supervisor)).join('&');
        } else if (businessUnit.length > 0) {
                params = businessUnit.map((businessUnit) => 'businessunits=' + businessUnit).join('&');
        }
        const relativeUrl = `/shifts?${params}&starting_date=${DateParse.formatDateForApi(startDate)}&ending_date=${DateParse.formatDateForApi(endDate)}`;

        const finalParam = relativeUrl.replace('&&', '&');
        const response = await di.get<Host2Api>(Host2ApiName).get(finalParam);

        if (!response) return {
                items: [],
                pages: 1,
                total: 0
        }

        const map: {
                [key: string]: {
                        user: UserEntity,
                        shifts: ShiftEntity[],
                },
        } = {};

        response.data.forEach((element: any) => {
                const userDto = UserHostDto.fromJson(element);
                const shiftDtoCop = ShiftHostDto.fromJson(element);
                if (!map[userDto.id]) {
                        map[userDto.id] = {
                                user: userDto,
                                shifts: [shiftDtoCop],
                        };
                }
                else {
                        map[userDto.id].shifts = [...map[userDto.id].shifts, shiftDtoCop];
                }
        });

        const passToArray = Object.keys(map).map(key => map[key]);
        
        return {
                items: passToArray,
                pages: 1,
                total: passToArray.length
        }
}

export default SearchUsersWithShiftApiImpl;
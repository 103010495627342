import { injectable } from "inversify";
import UserProvider from "../../providers/user/UserProvider";
import OffBoardingRepository, { SearchUserInfoResponse } from "../../repositories/OffBoardingRepository";
import { Either, left } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";
import KeyWordLocalization from "../../../ui/providers/localization/dictionaries/KeyWordLocalization";

interface _props {
    offBoardingRepository: OffBoardingRepository;
    userProvider: UserProvider;
}

@injectable()
export default class StartOffBoardingProcessUseCase {
    private offBoardingRepository: OffBoardingRepository;
    private userProvider: UserProvider;

    constructor({ offBoardingRepository, userProvider }: _props) {
        this.offBoardingRepository = offBoardingRepository;
        this.userProvider = userProvider;
    }

    call = async (noEditable: SearchUserInfoResponse, info: { elegibleForRehire: boolean; companyPropertyReturned: string; companyPropertyNotReturned?: string; terminationPhase: string; terminationType: string; voluntaryTerminationReason?: string; jobDissatisfactionReason?: string; newOtherJobDetailedReason?: string; involuntaryTerminationReason?: string; supportingComment?: string; resignationFiles: File[]; }): Promise<Either<ExceptionEntity, void>> => {
        if(this.userProvider.Actions.user?.permissions?.prc.create !== true) return left({code: KeyWordLocalization.UserNotAuthorized});
        const response = await this.offBoardingRepository.startTermination(noEditable, info);
        return response;
    }
}

export const StartOffBoardingProcessUseCaseName = 'StartOffBoardingProcessUseCase';
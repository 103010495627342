import { injectable } from "inversify";
import ModalsProvider from "../../providers/modal/ModalsProvider";
import NewFeaturesRepository from "../../repositories/NewFeaturesRepository";
import UserProvider from "../../providers/user/UserProvider";

interface props {
    newFeaturesRepository: NewFeaturesRepository;
    modalsProvider: ModalsProvider;
    userProvider: UserProvider;
}

@injectable()
export default class DontShowAgainNewFeaturesUseCase {
    newFeaturesRepository: NewFeaturesRepository;
    modalsProvider: ModalsProvider;
    userProvider: UserProvider;

    constructor(props: props) {
        this.newFeaturesRepository = props.newFeaturesRepository;
        this.modalsProvider = props.modalsProvider;
        this.userProvider = props.userProvider;
    }

    public call = async (): Promise<void> => {
        if (this.userProvider.Actions.user == null) return this.modalsProvider.Actions.closeModalCustom();
        this.newFeaturesRepository.dontShowAgain(this.userProvider.Actions.user);
        this.modalsProvider.Actions.closeModalCustom();
    }    
}

export const DontShowAgainNewFeaturesUseCaseName = "DontShowAgainNewFeaturesUseCase";
import React, { FC, useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import UserContext from "../../domain/providers/user/UserContext";
import UserContextType from "../../domain/providers/user/UserContextType";
import RoutesComponentProps from "./RoutesComponentProps";
import KeyWordLocalization from "../providers/localization/dictionaries/KeyWordLocalization";
import UserEntity from "../../domain/entities/UserEntity";
import { FaBook } from 'react-icons/fa';
import di from "../../di/DependencyInjection";
import LayoutComponent from "../components/Layout/LayoutComponent";
import LoadingComponent from "../components/LoadingComponent/LoadingComponent";
import NotFoundComponent from "../components/notFound/NotFoundComponent";
import SignInPage from "../pages/auth/signin/SignInPage";
import ConfirmAccountPage from "../pages/auth/confirmAccount/ConfirmAccountPage";
import SendCodeRecoveryPage from "../pages/auth/recovery/sendCodeRecovery/SendCodeRecoveryPage";
import UpdatePasswordRecoveryPage from "../pages/auth/recovery/updatePasswordRecovery/UpdatePasswordRecoveryPage";
import LoadUseCase, { LoadUseCaseName } from "../../domain/use_cases/default/LoadUseCase";
import SignUpPage from "../pages/auth/signUpPage/SignUpPage";
import PeoplePage from "../pages/people/list/PeoplePage";
import HomePage from "../pages/home/HomePage";
import UserRoles from "../pages/roles/list/RolesPage";
import UserRolesDetail from '../pages/roles/detail/RolesPageDetail';
import UserRolesEdition from '../pages/roles/edit/RoleEditPermissionsPage';
import UserRolesCreation from '../pages/roles/create/RoleCreatePermissionsPage';
import CalendarPersonPage from "../pages/schedule/calendarPerson/CalendarPersonPage";
import CalendarShiftPage from "../pages/schedule/calendarShift/CalendarShiftPage";
import ShiftListPage from "../pages/shift/shiftList/ShiftListPage";
import PersonDetailedPage from "../pages/people/list/personDetailed/PersonDetailedPage";
import Icons from "../assets/Icons";
import StaffPerShiftListPage from "../pages/shift/staffPerShift/StaffPerShiftListPage";

import PermissionsPage from "../pages/roles/permissions/info/PermissionsInfoPage";
import DataLoaderPage from "../pages/shift/dataLoader/DataLoaderPage";
import EditAttendancePage from "../pages/attendance/editAttendance/EdtiAttendancePage";
import TrackingDashboardPage from "../pages/attendance/trackingDashboard/TrackingDashboardPage";
import AiHome from "../pages/ai/AiPage";
import AiSummaryPage from "../pages/ai/AiSummaryPage";
import UserDeletePage from "../pages/people/list/components/userManagement/UserDeletePage";
import UserUpdatePage from "../pages/people/list/components/userManagement/UserUpdatePage";
import UserCreatePage from "../pages/people/list/components/userManagement/UserCreatePage";
import AiPageDash from "../pages/ai/AiPageDash";
import SearchCalls from "../pages/ai/SearchCalls";
import AiMoments from "../pages/ai/AiMoments";
import ResetUserPasswordPage from "../pages/auth/recovery/resetUserPassword/ResetUserPasswordPage";
import AttendanceDashboardPage from "../pages/attendance/dashboard/AttendanceDashboardPage";
import TerminationStartPage from "../pages/termination/terminationStart/TerminationStartPage";
import ShouldShowNewFeaturesUseCase, { ShouldShowNewFeaturesUseCaseName } from "../../domain/use_cases/newFeatures/ShouldShowNewFeaturesUseCase";
import NewFeaturesComponent from "../components/newFeatures/NewFeaturesComponent";
import ModalsProvider, { ModalsProviderName } from "../../domain/providers/modal/ModalsProvider";

export interface iRoute {
    name: string,
    path: string,
    relativePath: string | ((...args: any[]) => string),
    relativeFunction?: (...args: any[]) => string,
    component: FC,
    auth: (user: UserEntity | undefined) => boolean,
}

export interface iRouteModule {
    name: string,
    icon: any,
    page: iRoute | undefined,
    pages: iRoute[],

}
const routes = {
    signin: {
        name: KeyWordLocalization.RoutePageSignIn,
        path: "/",
        relativePath: '/',
        component: SignInPage,
        auth: (user: UserEntity | undefined) => user === undefined,
    },
    signin365: {
        name: KeyWordLocalization.RoutePageSignIn365,
        path: "/login365",
        relativePath: '/login365',
        component: SignInPage,
        auth: (user: UserEntity | undefined) => user === undefined,
    },
    signup: {
        name: KeyWordLocalization.RoutePageSignUp,
        path: "/signup",
        relativePath: '/signup',
        component: SignUpPage,
        auth: (user: UserEntity | undefined) => user === undefined,
    },
    home: {
        name: KeyWordLocalization.RoutePageHome,
        path: "/home",
        relativePath: '/home',
        component: HomePage,
        auth: (user: UserEntity | undefined) => user?.permissions?.role?.read == true,
    },
    employees: {
        name: KeyWordLocalization.RoutePageUsers,
        path: "/users",
        relativePath: '/users',
        component: PeoplePage,
        auth: (user: UserEntity | undefined) => user?.permissions?.employee?.read == true,
    },
    employeesDelete: {
        name: "UserDeletePage",
        path: "/users/delete",
        relativePath: '/users/delete',
        component: UserDeletePage,
        auth: (user: UserEntity | undefined) => user?.permissions?.user?.delete == true,
    },
    employeesUpdate: {
        name: "UserUpdatePage",
        path: "/users/update",
        relativePath: '/users/update',
        component: UserUpdatePage,
        auth: (user: UserEntity | undefined) => user?.permissions?.user?.update == true,
    },
    employeesCreate: {
        name: "UserCreatePage",
        path: "/users/create",
        relativePath: '/users/create',
        component: UserCreatePage,
        auth: (user: UserEntity | undefined) => user?.permissions?.user?.create == true,
    },
    rolesAndPermissions: {
        name: KeyWordLocalization.RoutePageRoles,
        path: "/roles",
        relativePath: '/roles',
        component: UserRoles,
        auth: (user: UserEntity | undefined) => user?.permissions?.role?.read == true,
    },
    rolesAndPermissionsDetail: {
        name: KeyWordLocalization.RoutePageRoleDetailed,
        path: "/roles/detail/:id",  // Add :id to make it a dynamic parameter
        relativePath: '/roles/detail',
        component: UserRolesDetail,
        auth: (user: UserEntity | undefined) => user?.permissions?.policy?.read == true,
    },
    rolesAndPermissionsEdition: {
        name: 'RolesEdition',
        path: "/roles/detail/edit/:id",  // Add :id to make it a dynamic parameter
        relativePath: '/roles/detail/edit',
        component: UserRolesEdition,
        auth: (user: UserEntity | undefined) => user?.permissions?.role?.update == true,
    },
    rolesAndPermissionsCreation: {
        name: 'RolesEdition',
        path: "/roles/create",  // Add :id to make it a dynamic parameter
        relativePath: '/roles/create',
        component: UserRolesCreation,
        auth: (user: UserEntity | undefined) => user?.permissions?.role?.create == true,
    },
    rolesAndPermissionsInfo: {
        name: 'Permissions',
        path: "/permissions",
        relativePath: '/permissions',
        component: PermissionsPage,
        auth: (user: UserEntity | undefined) => user?.permissions?.permission?.read == true,
    },
    employeeDetailed: {
        name: KeyWordLocalization.RoutePageUserDetailed,
        path: "/users/:userId",
        relativePath: '/users',
        component: PersonDetailedPage,
        auth: (user: UserEntity | undefined) => user?.permissions?.employee?.read == true,
    },
    my_calendar: {
        name: KeyWordLocalization.RoutePageMyCalendar,
        path: "/me/calendar",
        relativePath: '/me/calendar',
        component: CalendarPersonPage,
        auth: (user: UserEntity | undefined) => user?.permissions?.schedule?.read == true,
    },
    user_calendar: {
        name: KeyWordLocalization.RoutePageEmployeeCalendar,
        path: "/users/:userId/calendar",
        relativePath: (userId: string) => `/users/${userId}/calendar`,
        component: CalendarPersonPage,
        auth: (user: UserEntity | undefined) => user?.permissions?.schedule?.create == true,
    },
    send_code_recovery: {
        name: KeyWordLocalization.RoutePageSendCode,
        path: '/recovery/send-code',
        relativePath: '/recovery/send-code',
        component: SendCodeRecoveryPage,
        auth: (user: UserEntity | undefined) => user === undefined,
    },
    update_passowrd_recovery: {
        name: KeyWordLocalization.RoutePageUpdatePassword,
        path: '/recovery/update-password/:email',
        relativePath: '/recovery/update-password',
        component: UpdatePasswordRecoveryPage,
        auth: (user: UserEntity | undefined) => user === undefined,
    },
    reset_passowrd: {
        name: KeyWordLocalization.RoutePageResetPassword,
        path: '/reset-password/:email',
        relativePath: '/reset-password',
        component: ResetUserPasswordPage,
        auth: (user: UserEntity | undefined) => user === undefined,
    },
    confimr_account: {
        name: KeyWordLocalization.RoutePageConfirmAccount,
        path: '/confirm-account/:email',
        relativePath: '/confirm-account',
        component: ConfirmAccountPage,
        auth: (user: UserEntity | undefined) => user === undefined,
    },
    shift_calendar: {
        name: KeyWordLocalization.RoutePageShiftCalendar,
        path: '/shifts/calendar',
        relativePath: '/shifts/calendar',
        relativeFunction: (shiftId: string) => `/shifts/${shiftId}/calendar`,
        component: CalendarShiftPage,
        auth: (user: UserEntity | undefined) => user?.permissions?.schedule?.create == true,
    },
    creating_shift_calendar: {
        name: KeyWordLocalization.RoutePageCreatingShiftCalendar,
        path: '/shifts/create',
        relativePath: '/shifts/create',
        component: CalendarShiftPage,
        auth: (user: UserEntity | undefined) => user?.permissions?.schedule?.create == true,
    },
    shift_list: {
        name: KeyWordLocalization.RoutePageShifts,
        path: '/shifts',
        relativePath: '/shifts',
        component: ShiftListPage,
        auth: (user: UserEntity | undefined) => user?.permissions?.schedule?.create == true,
    },
    shift_per_supervisor: {
        name: KeyWordLocalization.RoutePageShiftPerSupervisor,
        path: '/tracking/shifts',
        relativePath: '/tracking/shifts',
        component: ShiftListPage,
        auth: (user: UserEntity | undefined) => user?.permissions?.schedule?.create == true,
    },
    staff_per_shift: {
        name: KeyWordLocalization.RoutePageStaffPerShift,
        path: '/shifts/staff-per-shift',
        relativePath: '/shifts/staff-per-shift',
        component: StaffPerShiftListPage,
        auth: (user: UserEntity | undefined) => user?.permissions?.schedule?.create == true,
    },
    data_bulk_loader: {
        name: KeyWordLocalization.RoutePageStaffPerShift,
        path: '/shifts/loader',
        relativePath: '/shifts/loader',
        component: DataLoaderPage,
        auth: (user: UserEntity | undefined) => user?.permissions?.schedule?.create == true,
    },
    edit_attendance: {
        name: KeyWordLocalization.RoutePageEditAttendanceAdjustments,
        path: '/attendance/edit',
        relativePath: '/attendance/edit',
        component: EditAttendancePage,
        auth: (user: UserEntity | undefined) => user?.permissions?.attendance?.read == true,
    },
    tracking_dashboard: {
        name: KeyWordLocalization.RoutePageEditAttendanceTracking,
        path: '/attendance/tracking',
        relativePath: '/attendance/tracking',
        component: TrackingDashboardPage,
        auth: (user: UserEntity | undefined) => user?.permissions?.attendance?.read == true,
    },
    attendance_dashboard: {
        name: KeyWordLocalization.RoutePageAttendanceDashboard,
        path: '/attendance/dashboard',
        relativePath: '/attendance/dashboard',
        component: AttendanceDashboardPage,
        auth: (user: UserEntity | undefined) => user?.permissions?.attendance?.read == true,
    },
    ai_home: {
        name: 'Call List',
        path: '/aihome',
        relativePath: '/aihome',
        component: AiHome,
        auth: (user: UserEntity | undefined) => user?.permissions?.aiTranscription?.read == true,
    },
    ai_search: {
        name: 'Search List',
        path: '/aisearch',
        relativePath: '/aisearch',
        component: SearchCalls,
        auth: (user: UserEntity | undefined) => user?.permissions?.aiTranscription?.assign == true,
    },
    ai_dash: {
        name: 'Dashboard',
        path: '/aidash',
        relativePath: '/aidash',
        component: AiPageDash,
        auth: (user: UserEntity | undefined) => user?.permissions?.aiDashboard?.read == true,
    },
    ai_summary: {
        name: 'AiSummary',
        path: '/aisummary/:recording/:format',
        relativePath: '/aisummary/:recording/:format',
        component: AiSummaryPage,
        auth: (user: UserEntity | undefined) => user?.permissions?.aiTranscription?.read == true,
    },
    ai_moments: {
        name: 'Questions and Moments',
        path: '/aimoments',
        relativePath: '/aimoments',
        component: AiMoments,
        auth: (user: UserEntity | undefined) => user?.permissions?.aiEvaluation?.create == true,
    },
    termination_start: {
        name: 'Termination',
        path: '/termination/start',
        relativePath: '/termination/start',
        component: TerminationStartPage,
        auth: (user: UserEntity | undefined) => true, //TODO add permissions
    }
}

const modules: iRouteModule[] = [
    {
        name: KeyWordLocalization.MeModule,
        page: undefined,
        icon: Icons.PersonOutlined,
        pages: [
            routes.my_calendar,
        ],
    },
    {
        name: KeyWordLocalization.PersonModule,
        page: routes.employees,
        icon: Icons.People,
        pages: [],
    },
    {
        name: KeyWordLocalization.ShiftsModule,
        page: routes.shift_list,
        icon: Icons.Calendar,
        pages: [],
    },
    {
        name: KeyWordLocalization.TrackingModule,
        page: undefined,
        icon: Icons.HourGlass,
        pages: [
            routes.staff_per_shift,
            routes.shift_per_supervisor,
        ],
    },
    {
        name: KeyWordLocalization.RolesModule,
        page: undefined,
        icon: Icons.LockOutlined,
        pages: [
            routes.rolesAndPermissions,
            routes.rolesAndPermissionsInfo,
            // routes.termination_start,
        ],
    },
    {
        name: KeyWordLocalization.AttendanceModule,
        page: undefined,
        icon: Icons.Clock,
        pages: [
            routes.edit_attendance,
            routes.tracking_dashboard,
            // routes.attendance_dashboard,
        ],
    },
    {
        name: KeyWordLocalization.AIModule,
        page: undefined,
        icon: Icons.RobotIcon,
        pages: [
            routes.ai_home,
            routes.ai_dash,
            routes.ai_moments
        ],
    },
    {
        name: KeyWordLocalization.PCRModule,
        page: undefined,
        icon: Icons.People,
        pages: [
            routes.termination_start,
        ],
    }
]

const RoutesComponent: React.FC<RoutesComponentProps> = ({ children }) => {
    const { user } = useContext(UserContext) as UserContextType;

    const [loaded, setLoaded] = useState<boolean>(false);

    const _load = async () => {
        try {
            await di.get<LoadUseCase>(LoadUseCaseName).call();
        } catch (_) {
        }
        setLoaded(true);
        const featuresToShow = await di.get<ShouldShowNewFeaturesUseCase>(ShouldShowNewFeaturesUseCaseName).call();
        if (featuresToShow.length > 0) {
            di.get<ModalsProvider>(ModalsProviderName).Actions.openModalCustom("lg", "", <NewFeaturesComponent features={featuresToShow} />);
        }
    }

    useEffect(() => {
        _load();
    }, []);

    if (!loaded) return <div className="bg_1" style={{ width: '100vw', height: '100vh' }}>
        <LoadingComponent showLogo />
    </div>
    return <>
        <BrowserRouter>
            <Routes>
                {Object.values(routes).map((route: any) => <Route key={route.path} path={route.path} element={
                    route.auth(user) ? <LayoutComponent >
                        {React.createElement(route.component, {}, undefined)}
                    </LayoutComponent > : <NotFoundComponent />
                }>
                </Route>)}
                <Route path='*' element={<NotFoundComponent />} />
            </Routes>
        </BrowserRouter>
    </>
}

export default RoutesComponent;
export { routes, RoutesComponent, modules };

import './NewFeaturesComponentStyles.scss';
import { FC, useContext } from "react";
import NewFeaturesComponentProps from "./NewFeaturesComponentProps";
import LocalizationContext from "../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../domain/providers/localization/LocalizationContextType";
import ModalsContextType from "../../../domain/providers/modal/ModalsContextType";
import ModalsContext from "../../../domain/providers/modal/ModalsContext";
import di from "../../../di/DependencyInjection";
import DontShowAgainNewFeaturesUseCase, { DontShowAgainNewFeaturesUseCaseName } from "../../../domain/use_cases/newFeatures/DontShowAgainNewFeaturesUseCase";
import ShowLaterNewFeaturesUseCase, { ShowLaterNewFeaturesUseCaseName } from "../../../domain/use_cases/newFeatures/ShowLaterNewFeaturesUseCase";
import KeyWordLocalization from "../../providers/localization/dictionaries/KeyWordLocalization";
import { Carousel } from 'react-responsive-carousel';
import NewFeatureEntity from '../../../domain/entities/NewFeatureEntity';

const NewFeaturesComponent: FC<NewFeaturesComponentProps> = ({ features }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { closeModalCustom } = useContext(ModalsContext) as ModalsContextType;

    const _handleDontShowAgain = () => {
        di.get<DontShowAgainNewFeaturesUseCase>(DontShowAgainNewFeaturesUseCaseName).call();
        closeModalCustom();
    }

    const _handleShowLater = () => {
        di.get<ShowLaterNewFeaturesUseCase>(ShowLaterNewFeaturesUseCaseName).call();
        closeModalCustom();
    }

    const _handleClick = (feature: NewFeatureEntity) => {
        if (feature.onClickUrl && feature.onClickUrl.length > 0) {
        window.open(feature.onClickUrl, '_blank');
        }
    }

    return (
        <div className="new_features_component">
            <div className="w-100 d-flex justify-content-center">
            <Carousel showThumbs={false} showArrows showIndicators>
                {features.map((feature, index) => (
                    <div key={index} onClick={()=>_handleClick(feature)}>
                        <img src={feature.image} alt={feature.image} className='banner_new_features'/>
                    </div>
                ))}
            </Carousel>
            </div>
            <div className="modal-footer w-100">
                <button className="btn btn_light mx-2" onClick={_handleShowLater}>{i18n(KeyWordLocalization.NewFeaturesComponentRemindMeTomorrow)}</button>
                <button className="btn btn_primary" onClick={_handleDontShowAgain}>{i18n(KeyWordLocalization.NewFeaturesComponentGotIt)}</button>
            </div>
        </div>
    );
};

export default NewFeaturesComponent;
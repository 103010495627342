import { FC, useContext, useState } from "react";
import "./TerminationStartPageStyles.scss";
import HorizontalSteperComponent from "../../../components/horizontalStepper/HorizontalStepperComponent";
import ButtonComponent from "../../../components/button/ButtonComponent";
import { useForm } from "react-hook-form";
import KeyWordLocalization from "../../../providers/localization/dictionaries/KeyWordLocalization";
import LocalizationContext from "../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import Validators from "../../../utils/Validators";
import { ErrorMessage } from "@hookform/error-message";
import di from "../../../../di/DependencyInjection";
import SearchEmployeeForOffBoardingUseCase, { SearchEmployeeForOffBoardingUseCaseName } from "../../../../domain/use_cases/offBoarding/SearchEmployeeForOffBoardingUseCase";
import { SearchUserInfoResponse, StartTerminationApiSearchUserInfoField } from "../../../../domain/repositories/OffBoardingRepository";
import { isLeft, isRight } from "fp-ts/lib/Either";
import ModalsContext from "../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";
import Icons from "../../../assets/Icons";
import NotFoundComponent from "../../../components/notFound/NotFoundComponent";
import { ButtonType } from "../../../components/button/ButtonComponentProps";
import StartOffBoardingProcessUseCase, { StartOffBoardingProcessUseCaseName } from "../../../../domain/use_cases/offBoarding/StartOffBoardingProcessUseCase";

const TerminationStartPage: FC<{}> = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [infoUser, setInfoUser] = useState<SearchUserInfoResponse | null | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [filesSupporting, setFilesSupporting] = useState<File[]>([]);

    const steps = [
        KeyWordLocalization.TerminationStartPageEmployeeSearch,
        KeyWordLocalization.TerminationStartPageConfirmEmployee,
        KeyWordLocalization.TerminationStartPageTerminationInformation,
        KeyWordLocalization.TerminationStartPageAttachments];
    const formFunctions = useForm();
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const { register, handleSubmit, getValues, setValue, watch, reset, formState: { errors } } = formFunctions;
    const fields = {
        fieldSearch: KeyWordLocalization.TerminationStartPageFieldSearch,
        wordSearch: KeyWordLocalization.TerminationStartPageWordSearch,
        lastDayWorked: KeyWordLocalization.TerminationStartPageLastDayWorked,
        dateNotifiedTermination: KeyWordLocalization.TerminationStartPageDateNotifiedTermination,
        terminationDate: KeyWordLocalization.TerminationStartPageTerminationDate,
        elegibleForRehire: KeyWordLocalization.TerminationStartPageElegibleForRehire,
        companyPropertyReturned: KeyWordLocalization.TerminationStartPageCompanyPropertyReturned,
        companyPropertyNotReturned: KeyWordLocalization.TerminationStartPageCompanyPropertyNotReturned,
        terminationPhase: KeyWordLocalization.TerminationStartPageTerminationPhase,
        terminationType: KeyWordLocalization.TerminationStartPageTerminationType,
        voluntaryTerminationReason: KeyWordLocalization.TerminationStartPageVoluntaryTerminationReason,
        jobDissatisfactionReason: KeyWordLocalization.TerminationStartPageJobDissatisfactionReason,
        newJobOtherReason: KeyWordLocalization.TerminationStartPageNewJobOtherReason,
        involuntaryTerminationReason: KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReason,
        supportingCommentary: KeyWordLocalization.TerminationStartPageSupportingCommentary,
        supportingDocumentation: KeyWordLocalization.TerminationStartPageSupportingDocumentation,
        hiddenSupportingDocumentationValidator: KeyWordLocalization.TerminationStartPageHiddenSupportingDocumentationValidator,
    }

    const _handleSearchUserInfo = async (field: StartTerminationApiSearchUserInfoField, value: string) => {
        console.log(field, value);
        setLoading(true);
        setInfoUser(undefined);
        const response = await di.get<SearchEmployeeForOffBoardingUseCase>(SearchEmployeeForOffBoardingUseCaseName).call(field, value);
        if (isRight(response)) {
            setInfoUser(response.right);
            setCurrentStep(1);
        } else {
            setInfoUser(null);
        }
        setLoading(false);
    }

    const _handleChangeCompanyPropertyReturned = (e: any) => {
        const val = e.target.value;
        console.log('on changed company property returned', val);
        if (val == KeyWordLocalization.TerminationStartPageCompanyPropertyNotReturnedValueNo || val == KeyWordLocalization.TerminationStartPageCompanyPropertyNotReturnedValuePartially)
            setValue(fields.companyPropertyNotReturned, '');
    }

    const _handleChangeTerminationType = (e: any) => {
        const val = e.target.value;
        console.log('on changed termination type', val);
        if (val == KeyWordLocalization.TerminationStartPageTerminationTypeValueVoluntary)
            setValue(fields.involuntaryTerminationReason, '');
        else if (val == KeyWordLocalization.TerminationStartPageTerminationTypeValueInvoluntary || val == KeyWordLocalization.TerminationStartPageTerminationTypeValueNeverStarted) {
            setValue(fields.voluntaryTerminationReason, '');
            setValue(fields.jobDissatisfactionReason, '');
            setValue(fields.newJobOtherReason, '');
        }
    }

    const _handleOnChangeVoluntaryTerminationReason = (e: any) => {
        const val = e.target.value;
        console.log('on changed voluntary termination reason', val);
        if (val != KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueDissatisfaction)
            setValue(fields.jobDissatisfactionReason, '');
        else if (val != KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueNewJob)
            setValue(fields.newJobOtherReason, '');
    }

    const _handleOnSubmit = async (data: any) => {
        if (currentStep == 0 && infoUser == null) return _handleSearchUserInfo(data[fields.fieldSearch], data[fields.wordSearch]);
        else if (currentStep == 3) return _handleSendTermination(data);
        else if (currentStep < steps.length - 1)
            return setCurrentStep(currentStep + 1);
    }

    const _handleSendTermination = async (data: any) => {
        if(infoUser == null) return;
        const dataParsed = {
            elegibleForRehire: data[fields.elegibleForRehire],
            companyPropertyReturned: data[fields.companyPropertyReturned],
            companyPropertyNotReturned: data[fields.companyPropertyNotReturned],
            terminationPhase: data[fields.terminationPhase],
            terminationType: data[fields.terminationType],
            voluntaryTerminationReason: data[fields.voluntaryTerminationReason],
            jobDissatisfactionReason: data[fields.jobDissatisfactionReason],
            newOtherJobDetailedReason: data[fields.newJobOtherReason],
            involuntaryTerminationReason: data[fields.involuntaryTerminationReason],
            supportingComment: data[fields.supportingCommentary],
            resignationFiles: filesSupporting
        };
        const response = await di.get<StartOffBoardingProcessUseCase>(StartOffBoardingProcessUseCaseName).call(infoUser!, dataParsed);
        if(isLeft(response)) return addToast(response.left.code ?? KeyWordLocalization.UnknownError, 'error', undefined);
        else {
            addToast(KeyWordLocalization.TerminationStartPageSuccess, 'success', undefined);
            setInfoUser(undefined);
            setCurrentStep(0);
            reset();
        }
    }

    const _handleOnChangeFilesSupporting = (e: any) => {
        const selectedFiles = e.target.files;

        if (!selectedFiles) return;

        // Convertimos FileList a un array de archivos
        const newFiles = Array.from(selectedFiles);

        // Filtramos los archivos que ya están en filesSupporting
        setValue(fields.supportingDocumentation, null);
        const uniqueFiles = newFiles.filter(
            (file: any) =>
                !filesSupporting.some(
                    (existingFile) => existingFile.name === file.name && existingFile.size === file.size
                )
        );

        // Si hay archivos nuevos, los agregamos al estado
        if (uniqueFiles.length > 0) {
            setFilesSupporting((prevFiles: any) => {
                const newVal = [...prevFiles, ...uniqueFiles];
                if (newVal.length > 10) setValue(fields.hiddenSupportingDocumentationValidator, newVal.map(i => "a").join(""), { shouldValidate: true });
                return newVal;
            });
        }
    }

    const _handleRemoveFile = (file: File) => {
        setFilesSupporting((prevFiles: any) => prevFiles.filter((f: any) => f.name !== file.name));
    }


    const _ItemUser: FC<{ title: string, value: string }> = ({ title, value }) => {
        return <div className="item_user col-sm-6 my-3 col-md-4 col-lg-3">
            <div className="title">{title}</div>
            <div className="value">{value}</div>
        </div>
    }

    return <div className="termination_start_page">
        <form onSubmit={handleSubmit(_handleOnSubmit)}>
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <HorizontalSteperComponent changeStep={setCurrentStep} currentStep={currentStep} steps={steps.map((step) => i18n(step))}>
                            {currentStep >= 0 ? <section> {/*  Employee Search */}
                                <div className="row">
                                    <div className="col-6 my-3">
                                        <div className={`${errors[fields.fieldSearch] && 'error'} form-group`}>
                                            <label>{i18n(fields.fieldSearch)}</label>
                                            <select defaultValue={StartTerminationApiSearchUserInfoField.EMAIL} {...register(fields.fieldSearch, Validators({
                                                onChange: () => setInfoUser(undefined),
                                                required: true,
                                            }))} className="form-control">
                                                <option value={StartTerminationApiSearchUserInfoField.EMAIL}>{i18n(KeyWordLocalization.TerminationStartPageFieldSearchEmail)}</option>
                                                <option value={StartTerminationApiSearchUserInfoField.NAME}>{i18n(KeyWordLocalization.TerminationStartPageFieldSearchName)}</option>
                                                <option value={StartTerminationApiSearchUserInfoField.HRM_ID}>{i18n(KeyWordLocalization.TerminationStartPageFieldSearchHrm)}</option>
                                            </select>
                                            <ErrorMessage errors={errors} name={fields.fieldSearch} as="aside" />
                                        </div>
                                    </div>
                                    <div className="col-6 my-3">
                                        <div className={`${errors[fields.wordSearch] && 'error'} form-group`}>
                                            <label>{i18n(fields.wordSearch)}</label>
                                            <input placeholder={i18n(fields.wordSearch)} {...register(fields.wordSearch, Validators({
                                                onChange: () => setInfoUser(undefined),
                                                required: true,
                                            }))} type="text" className="form-control" />
                                            <ErrorMessage errors={errors} name={fields.wordSearch} as="aside" />
                                        </div>
                                    </div>
                                </div>
                                {infoUser === null && <NotFoundComponent />}
                                <div className="d-flex justify-content-end">
                                    <ButtonComponent typeAction="submit" icon={<Icons.Search />} isLoading={loading} text={i18n(KeyWordLocalization.Search)} ></ButtonComponent>
                                </div>
                            </section> : <></>}
                            {currentStep >= 1 ? <section className="confirmation_employee"> {/*  Position Information */}
                                {infoUser && <>
                                    <div className="row my-3">
                                        <div className="w-100 d-flex flex-wrap">
                                            <div className="circle-image-container">
                                                <img src={infoUser.image ?? '/assets/no_photo_profile.svg'} alt="" className="img_rounded img-fluid" />
                                            </div>
                                            <div className="d-flex ms-4 flex-column">
                                                <strong>{infoUser.name}</strong>
                                                <span>{infoUser.department}</span>
                                            </div>
                                            <div className="mt-3 mt-md-0 ms-lg-4 flex-grow-1 d-flex flex-column align-items-end">
                                                <div className="flex-grow-1 d-flex align-items-center text_ellipsis text-left">
                                                    <div className="me-1">
                                                        <Icons.Email width={30} />
                                                    </div>
                                                    <div className="flex-grow-1 text_ellipsis text-left">
                                                        {infoUser.email}
                                                    </div>
                                                </div>
                                                <div className="d-flex align-center hover">
                                                    <div className="me-1">
                                                        <Icons.PersonRounded width={25} height={30} />
                                                    </div>
                                                    <div className="flex-grow-1 d-flex align-items-center text_ellipsis text-left">
                                                        #{infoUser.hrmId}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-2">
                                            <hr className="mt-3" />
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <_ItemUser title={i18n(KeyWordLocalization.TerminationStartPageBirthdate)} value={infoUser.birthdate.toLocaleDateString()} />
                                        <_ItemUser title={i18n(KeyWordLocalization.TerminationStartPageGender)} value={infoUser.gender} />
                                        <_ItemUser title={i18n(KeyWordLocalization.TerminationStartPageLanguage)} value={infoUser.language} />
                                        <_ItemUser title={i18n(KeyWordLocalization.TerminationStartPageHireDate)} value={infoUser.startDate.toLocaleDateString()} />
                                        <_ItemUser title={i18n(KeyWordLocalization.TerminationStartPagePersonalEmail)} value={infoUser.personalEmail} />
                                        <_ItemUser title={i18n(KeyWordLocalization.TerminationStartPageSupervisor)} value={infoUser.employeeSupervisor.name} />
                                        <_ItemUser title={i18n(KeyWordLocalization.TerminationStartPageSupervisorEmail)} value={infoUser.employeeSupervisor.email} />
                                        <_ItemUser title={i18n(KeyWordLocalization.TerminationStartPageLocation)} value={infoUser.location} />
                                        <_ItemUser title={i18n(KeyWordLocalization.TerminationStartPageWorkLocation)} value={infoUser.workLocation} />
                                        <_ItemUser title={i18n(KeyWordLocalization.TerminationStartPagePositionAtMomentOfTermination)} value={infoUser.positionAtMomentOfTermination} />

                                    </div>
                                </>}
                                <div className="d-flex justify-content-between">
                                    <ButtonComponent typeAction="button" type={ButtonType.MAIN_OUTLINE} icon={<Icons.BackArrowCircle />} text={i18n(KeyWordLocalization.Back)} onClick={() => setCurrentStep(currentStep - 1)} ></ButtonComponent>
                                    <ButtonComponent typeAction="submit" text={i18n(KeyWordLocalization.Next)} icon={<Icons.ArrowCircle />} ></ButtonComponent>
                                </div>

                            </section> : <></>}
                            {currentStep >= 2 ? <section className="termination_data"> {/*  Termination Information */}
                                <input type="hidden" {...register('ignore')} />
                                <div className="row">
                                    <div className="form_section">End notification</div>
                                    <div className="col-6 mb-3">
                                        <div className={`${errors[fields.lastDayWorked] && 'error'} form-group`}>
                                            <label>{i18n(fields.lastDayWorked)}</label>
                                            <input placeholder={i18n(fields.lastDayWorked)} {...register(fields.lastDayWorked, Validators({
                                                required: true,
                                            }))} type="date" className="form-control" />
                                            <ErrorMessage errors={errors} name={fields.lastDayWorked} as="aside" />
                                        </div>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <div className={`${errors[fields.dateNotifiedTermination] && 'error'} form-group`}>
                                            <label>{i18n(fields.dateNotifiedTermination)}</label>
                                            <input placeholder={i18n(fields.dateNotifiedTermination)} {...register(fields.dateNotifiedTermination, Validators({
                                                required: true,
                                            }))} type="date" className="form-control" />
                                            <ErrorMessage errors={errors} name={fields.dateNotifiedTermination} as="aside" />
                                        </div>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <div className={`${errors[fields.terminationDate] && 'error'} form-group`}>
                                            <label>{i18n(fields.terminationDate)}</label>
                                            <input placeholder={i18n(fields.terminationDate)} {...register(fields.terminationDate, Validators({
                                                required: true,
                                            }))} type="date" className="form-control" />
                                            <ErrorMessage errors={errors} name={fields.terminationDate} as="aside" />
                                        </div>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <div className={`${errors[fields.elegibleForRehire] && 'error'} form-group`}>
                                            <label>{i18n(fields.elegibleForRehire)}</label>
                                            <select {...register(fields.elegibleForRehire, Validators({
                                                required: true,
                                            }))} className="form-control">
                                                <option value="">{i18n(fields.elegibleForRehire)}</option>
                                                <option value={KeyWordLocalization.Yes}>{i18n(KeyWordLocalization.Yes)}</option>
                                                <option value={KeyWordLocalization.No}>{i18n(KeyWordLocalization.No)}</option>
                                            </select>
                                            <ErrorMessage errors={errors} name={fields.elegibleForRehire} as="aside" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form_section">Company Property</div>
                                    <div className="col-12 mb-3">
                                        <div className={`${errors[fields.companyPropertyReturned] && 'error'} form-group`}>
                                            <label>{i18n(fields.companyPropertyReturned)}</label>
                                            <select {...register(fields.companyPropertyReturned, Validators({
                                                onChange: _handleChangeCompanyPropertyReturned,
                                                required: true,
                                            }))} className="form-control">
                                                <option value="">{i18n(fields.companyPropertyReturned)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageCompanyPropertyNotReturnedValueYes}>{i18n(KeyWordLocalization.TerminationStartPageCompanyPropertyNotReturnedValueYes)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageCompanyPropertyNotReturnedValueNo}>{i18n(KeyWordLocalization.TerminationStartPageCompanyPropertyNotReturnedValueNo)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageCompanyPropertyNotReturnedValuePartially}>{i18n(KeyWordLocalization.TerminationStartPageCompanyPropertyNotReturnedValuePartially)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageCompanyPropertyNotReturnedValueNotApplicable}>{i18n(KeyWordLocalization.TerminationStartPageCompanyPropertyNotReturnedValueNotApplicable)}</option>
                                            </select>
                                            <ErrorMessage errors={errors} name={fields.companyPropertyReturned} as="aside" />
                                        </div>
                                    </div>
                                    {(watch(fields.companyPropertyReturned) == KeyWordLocalization.TerminationStartPageCompanyPropertyNotReturnedValueNo || watch(fields.companyPropertyReturned) == KeyWordLocalization.TerminationStartPageCompanyPropertyNotReturnedValuePartially) && <div className="col-12 mb-3">
                                        <div className={`${errors[fields.companyPropertyNotReturned] && 'error'} form-group`}>
                                            <label>{i18n(fields.companyPropertyNotReturned)}</label>
                                            <textarea placeholder={i18n(fields.companyPropertyNotReturned)} {...register(fields.companyPropertyNotReturned, Validators({
                                                required: true,
                                            }))} className="form-control" />
                                            <ErrorMessage errors={errors} name={fields.companyPropertyNotReturned} as="aside" />
                                        </div>
                                    </div>}
                                </div>
                                <div className="row">
                                    <div className="form_section">Termination Phase</div>
                                    <div className="col-6 mb-3">
                                        <div className={`${errors[fields.terminationPhase] && 'error'} form-group`}>
                                            <label>{i18n(fields.terminationPhase)}</label>
                                            <select {...register(fields.terminationPhase, Validators({
                                                required: true,
                                            }))} className="form-control">
                                                <option value="">{i18n(fields.terminationPhase)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageTerminationPhaseValueProduction}>{i18n(KeyWordLocalization.TerminationStartPageTerminationPhaseValueProduction)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageTerminationPhaseValueNesting}>{i18n(KeyWordLocalization.TerminationStartPageTerminationPhaseValueNesting)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageTerminationPhaseValueTraining}>{i18n(KeyWordLocalization.TerminationStartPageTerminationPhaseValueTraining)}</option>
                                            </select>
                                            <ErrorMessage errors={errors} name={fields.terminationPhase} as="aside" />
                                        </div>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <div className={`${errors[fields.terminationType] && 'error'} form-group`}>
                                            <label>{i18n(fields.terminationType)}</label>
                                            <select {...register(fields.terminationType, Validators({
                                                required: true,
                                                onChange: _handleChangeTerminationType,
                                            }))} className="form-control">
                                                <option value="">{i18n(fields.terminationType)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageTerminationTypeValueVoluntary}>{i18n(KeyWordLocalization.TerminationStartPageTerminationTypeValueVoluntary)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageTerminationTypeValueInvoluntary}>{i18n(KeyWordLocalization.TerminationStartPageTerminationTypeValueInvoluntary)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageTerminationTypeValueNeverStarted}>{i18n(KeyWordLocalization.TerminationStartPageTerminationTypeValueNeverStarted)}</option>
                                            </select>
                                            <ErrorMessage errors={errors} name={fields.terminationType} as="aside" />
                                        </div>
                                    </div>
                                    {watch(fields.terminationType) == KeyWordLocalization.TerminationStartPageTerminationTypeValueVoluntary && <div className="col-6 mb-3">
                                        <div className={`${errors[fields.voluntaryTerminationReason] && 'error'} form-group`}>
                                            <label>{i18n(fields.voluntaryTerminationReason)}</label>
                                            <select {...register(fields.voluntaryTerminationReason, Validators({
                                                required: true,
                                                onChange: _handleOnChangeVoluntaryTerminationReason,
                                            }))} className="form-control">
                                                <option value="">{i18n(fields.voluntaryTerminationReason)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueDidNotReturnFromLeave}>{i18n(KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueDidNotReturnFromLeave)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueFamilyIssues}>{i18n(KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueFamilyIssues)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueIllnes}>{i18n(KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueIllnes)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueJobAbandonment}>{i18n(KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueJobAbandonment)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueDissatisfaction}>{i18n(KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueDissatisfaction)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueLegal}>{i18n(KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueLegal)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueMigration}>{i18n(KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueMigration)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueNewJob}>{i18n(KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueNewJob)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValuePersonalReasons}>{i18n(KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValuePersonalReasons)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueQuitForSchool}>{i18n(KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueQuitForSchool)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueTransportationIssues}>{i18n(KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueTransportationIssues)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueWalkeOff}>{i18n(KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueWalkeOff)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueWitouthReason}>{i18n(KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueWitouthReason)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueCompensation}>{i18n(KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueCompensation)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueMutualAgreement}>{i18n(KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueMutualAgreement)}</option>
                                            </select>
                                            <ErrorMessage errors={errors} name={fields.voluntaryTerminationReason} as="aside" />
                                        </div>
                                    </div>}

                                    {watch(fields.voluntaryTerminationReason) == KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueDissatisfaction && <div className="col-6 mb-3">
                                        <div className={`${errors[fields.jobDissatisfactionReason] && 'error'} form-group`}>
                                            <label>{i18n(fields.jobDissatisfactionReason)}</label>
                                            <select {...register(fields.jobDissatisfactionReason, Validators({
                                                required: true,
                                            }))} className="form-control">
                                                <option value="">{i18n(fields.jobDissatisfactionReason)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageJobDissatisfactionReasonValueLeadership}>{i18n(KeyWordLocalization.TerminationStartPageJobDissatisfactionReasonValueLeadership)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageJobDissatisfactionReasonValueWorkEnviroment}>{i18n(KeyWordLocalization.TerminationStartPageJobDissatisfactionReasonValueWorkEnviroment)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageJobDissatisfactionReasonValueNotAlignedWithExpectations}>{i18n(KeyWordLocalization.TerminationStartPageJobDissatisfactionReasonValueNotAlignedWithExpectations)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageJobDissatisfactionReasonValueCompensation}>{i18n(KeyWordLocalization.TerminationStartPageJobDissatisfactionReasonValueCompensation)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageJobDissatisfactionReasonValueIssuesWithLineManager}>{i18n(KeyWordLocalization.TerminationStartPageJobDissatisfactionReasonValueIssuesWithLineManager)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageJobDissatisfactionReasonValueOther}>{i18n(KeyWordLocalization.TerminationStartPageJobDissatisfactionReasonValueOther)}</option>
                                            </select>
                                            <ErrorMessage errors={errors} name={fields.jobDissatisfactionReason} as="aside" />
                                        </div>
                                    </div>}

                                    {watch(fields.voluntaryTerminationReason) == KeyWordLocalization.TerminationStartPageVoluntaryTerminationReasonValueNewJob && <div className="col-6 mb-3">
                                        <div className={`${errors[fields.newJobOtherReason] && 'error'} form-group`}>
                                            <label>{i18n(fields.newJobOtherReason)}</label>
                                            <select {...register(fields.newJobOtherReason, Validators({
                                                required: true,
                                            }))} className="form-control">
                                                <option value="">{i18n(fields.newJobOtherReason)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageNewJobOtherReasonValueAlignedWithCareerPath}>{i18n(KeyWordLocalization.TerminationStartPageNewJobOtherReasonValueAlignedWithCareerPath)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageNewJobOtherReasonValueBetterCompensation}>{i18n(KeyWordLocalization.TerminationStartPageNewJobOtherReasonValueBetterCompensation)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageNewJobOtherReasonValueGrowhtOpportunity}>{i18n(KeyWordLocalization.TerminationStartPageNewJobOtherReasonValueGrowhtOpportunity)}</option>
                                            </select>
                                            <ErrorMessage errors={errors} name={fields.newJobOtherReason} as="aside" />
                                        </div>
                                    </div>}

                                    {watch(fields.terminationType) == KeyWordLocalization.TerminationStartPageTerminationTypeValueInvoluntary && <div className="col-6 mb-3">
                                        <div className={`${errors[fields.involuntaryTerminationReason] && 'error'} form-group`}>
                                            <label>{i18n(fields.involuntaryTerminationReason)}</label>
                                            <select {...register(fields.involuntaryTerminationReason, Validators({
                                                required: true,
                                            }))} className="form-control">
                                                <option value="">{i18n(fields.involuntaryTerminationReason)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueAbsenteeism}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueAbsenteeism)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueDamageOfCompanyProperty}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueDamageOfCompanyProperty)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueUnsatisfactoryPerformance}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueUnsatisfactoryPerformance)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueFailedTraining}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueFailedTraining)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueSeasonalWorker}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueSeasonalWorker)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueTheftDeshonesty}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueTheftDeshonesty)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueRefusedDrugTesting}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueRefusedDrugTesting)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueLayoffRif}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueLayoffRif)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueMisconduct}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueMisconduct)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueFalsificationOfCompanyReports}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueFalsificationOfCompanyReports)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueFigthingOrThreateningViolence}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueFigthingOrThreateningViolence)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueFailedProbation}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueFailedProbation)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueLostOfLicense}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueLostOfLicense)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueRelocation}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueRelocation)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueRetired}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueRetired)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueDeath}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueDeath)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueEmployeeInelegibilty}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueEmployeeInelegibilty)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueRedundancy}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueRedundancy)}</option>
                                                <option value={KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueBreachOfPolicy}>{i18n(KeyWordLocalization.TerminationStartPageInvoluntaryTerminationReasonValueBreachOfPolicy)}</option>
                                            </select>
                                            <ErrorMessage errors={errors} name={fields.involuntaryTerminationReason} as="aside" />
                                        </div>
                                    </div>}
                                </div>
                                <div className="d-flex justify-content-between mt-3">
                                    <ButtonComponent typeAction="button" type={ButtonType.MAIN_OUTLINE} icon={<Icons.BackArrowCircle />} text={i18n(KeyWordLocalization.Back)} onClick={() => setCurrentStep(currentStep - 1)} ></ButtonComponent>
                                    <ButtonComponent typeAction="submit" text={i18n(KeyWordLocalization.Next)} icon={<Icons.ArrowCircle />} ></ButtonComponent>
                                </div>
                            </section> : <></>}
                            {currentStep >= 3 ? <section className="attachments"> {/*  Attachments */}
                                <div className="row">
                                    <div className="col-12 my-3">
                                        <div className={`${errors[fields.supportingCommentary] && 'error'} form-group`}>
                                            <label>{i18n(fields.supportingCommentary)}</label>
                                            <textarea placeholder={i18n(fields.supportingCommentary)} {...register(fields.supportingCommentary, Validators({
                                                required: true,
                                            }))} className="form-control" />
                                            <ErrorMessage errors={errors} name={fields.supportingCommentary} as="aside" />
                                        </div>
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className={`${errors[fields.hiddenSupportingDocumentationValidator] && 'error'} form-group`}>
                                            <label>{i18n(fields.supportingDocumentation)}</label>
                                            <input placeholder={i18n(fields.supportingDocumentation)} {...register(fields.supportingDocumentation, Validators({
                                                maxLength: 10,
                                                onChange: _handleOnChangeFilesSupporting,
                                            }))} type="file" multiple max={10} className="form-control" />
                                            <input type="hidden" {...register(fields.hiddenSupportingDocumentationValidator, Validators({
                                                maxLength: 10,
                                            }))} />
                                            <ErrorMessage errors={errors} name={fields.hiddenSupportingDocumentationValidator} as="aside" />
                                        </div>
                                        <div className="row my-3">
                                            {filesSupporting.map((file, index) => <div key={index} className="col-6 my-1">
                                                <div className="file_item">
                                                    <div className="file_icon">
                                                        <Icons.File />
                                                    </div>
                                                    <div className="file_text">
                                                        <div className="file_name">{file.name}</div>
                                                        <div className="file_size">{(file.size / 1024).toFixed(2)} KB</div>
                                                    </div>
                                                    <div className="close hover" onClick={() => _handleRemoveFile(file)}>
                                                        <Icons.Trash />
                                                    </div>
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mt-3">
                                    <ButtonComponent typeAction="button" type={ButtonType.MAIN_OUTLINE} icon={<Icons.BackArrowCircle />} text={i18n(KeyWordLocalization.Back)} onClick={() => setCurrentStep(currentStep - 1)} ></ButtonComponent>
                                    <ButtonComponent typeAction="submit" text={i18n(KeyWordLocalization.Save)} icon={<Icons.CheckRounded />} ></ButtonComponent>
                                </div>
                            </section> : <></>}
                        </HorizontalSteperComponent>

                    </div>
                </div>

            </div>
        </form>
    </div>
}

export default TerminationStartPage;
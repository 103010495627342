import UserEntity from "../../../../../domain/entities/UserEntity";

const ShowLaterNewFeaturesApiImpl = async (keySaved: string, user: UserEntity): Promise<void> => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    try {
        const APP_CACHE_KEY_DELETER = 'app_cache_key_deleter';
        const lastestVersion = parseInt(localStorage.getItem(APP_CACHE_KEY_DELETER) || '0');
        const loadSaved = localStorage.getItem(keySaved);
        
        //keySaved is {email: string, lastVersionSeen: number, whenRemember: date | never}
        let parsedData = [];

        try {
            parsedData = JSON.parse(loadSaved ?? '[]');
        } catch (error) {}

        let addNew = true;

        parsedData.forEach((element: any) => {
            if (element.email == user.email) {
                element.whenRemember = tomorrow.toISOString();
                element.lastVersionSeen = lastestVersion;
                addNew = false;
            }
        });

        if (addNew)
            parsedData.push({ email: user.email, lastVersionSeen: lastestVersion, whenRemember: tomorrow.toISOString() });
            localStorage.setItem(keySaved, JSON.stringify(parsedData));
        return;
    } catch (error) {
        return;
    }
}

export default ShowLaterNewFeaturesApiImpl;
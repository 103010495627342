import { FC, useContext, useState } from 'react';
import './TrackingDashboardStyles.scss';
import { set, useForm } from 'react-hook-form';
import ModalsContext from '../../../../domain/providers/modal/ModalsContext';
import ModalsContextType from '../../../../domain/providers/modal/ModalsContextType';
import SearchWithGroupsComponent from '../../../components/searchWithGroups/SearchWithGroupsComponent';
import di from '../../../../di/DependencyInjection';
import BusinessUnitEntity from '../../../../domain/entities/BusinessUnitEntity';
import UserEntity from '../../../../domain/entities/UserEntity';
import SearchBusinessUnitUseCase, { SearchBusinessUnitUseCaseName } from '../../../../domain/use_cases/businessUnit/SearchBusinessUnitUseCase';
import SearchAgentsUseCase, { SearchAgentsUseCaseName } from '../../../../domain/use_cases/user/SearchAgentsUseCase';
import SearchSupervisorUseCase, { SearchSupervisorUseCaseName } from '../../../../domain/use_cases/user/SearchSupervisorUseCase';
import KeyWordLocalization from '../../../providers/localization/dictionaries/KeyWordLocalization';
import { ErrorMessage } from '@hookform/error-message';
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import Validators from '../../../utils/Validators';
import GetSummaryAttendanceInTimeRangeUseCase, { GetSummaryAttendanceInTimeRangeUseCaseName } from '../../../../domain/use_cases/trackingTime/GetSummaryAttendanceInTimeRangeUseCase';
import { GetSummaryAttendanceInTimeResponse, SummaryTypeAttendance } from '../../../../domain/repositories/TrackingTimeRepository';
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent';
import NotResultsComponent from '../../../components/notResults/NotResultsComponent';
import { isLeft } from 'fp-ts/lib/Either';
import TrackingCardResultComponent from './components/trackingCardResult/TrackingCardResultComponent';
import TrackingCompareLineComponent from './components/trackingCompareLine/TrackingCompareLineComponent';
import TrackingCardTableEventCompareComponent from './components/trackingCardTableEventCompare/TrackingCardTableEventCompareComponent';
import PieChartDashboardComponent from './components/pieChart/PieChartDashboardComponent';
import GroupEntity from '../../../../domain/entities/GroupEntity';

const TrackingDashboardPage: FC<{}> = () => {
    const formFunctions = useForm();
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const { register, getValues, setValue, watch, formState: { errors } } = formFunctions;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const [isLoading, setIsLoading] = useState(false);
    const [resultData, setResultData] = useState<GetSummaryAttendanceInTimeResponse | undefined | null>(null);
    const itemsPerPage = 20;


    const _searchBusinessUnit = async (word: string): Promise<BusinessUnitEntity[]> => {
        const response = await di.get<SearchBusinessUnitUseCase>(SearchBusinessUnitUseCaseName).call(word, 1, itemsPerPage, [],);
        return response.items;
    }
    const _searchSupervisor = async (word: string, businessUnit: string[]): Promise<UserEntity[]> => {
        const response = await di.get<SearchSupervisorUseCase>(SearchSupervisorUseCaseName).call(word, 1, itemsPerPage, businessUnit);
        return response.items;
    };
    const _searchEmployees = async (word: string, businessUnit: string[], supervisors: string[]): Promise<UserEntity[]> => {
        const filter: { [key: string]: any } = {};
        filter[KeyWordLocalization.UserEntityBUAssociated] = businessUnit;
        const response = await di.get<SearchAgentsUseCase>(SearchAgentsUseCaseName).call(word, 1, itemsPerPage, businessUnit, supervisors);
        return response.items;
    }


    const _handleOnChangeSearch = async (businessId: BusinessUnitEntity[], supervisorId: UserEntity[], employeesId: UserEntity[], groups: GroupEntity[]): Promise<void> => {
        setValue(KeyWordLocalization.TrackingDashboardPageBusinessesIds, businessId);
        setValue(KeyWordLocalization.TrackingDashboardPageSupervisor, supervisorId);
        setValue(KeyWordLocalization.TrackingDashboardPageEmployees, supervisorId);
        setValue(KeyWordLocalization.TrackingDashboardPagePage, 1);
        setValue(KeyWordLocalization.TrackingDashboardPageItemsHasMore, true);

        //date init parse
        const dateInitString = getValues()[KeyWordLocalization.TrackingDashboardPageDateInit];
        const splittedDateInit = dateInitString.split('-');
        const dateAsDateInit = new Date(splittedDateInit[0], splittedDateInit[1] - 1, splittedDateInit[2]);

        //date end parse
        const dateEndString = getValues()[KeyWordLocalization.TrackingDashboardPageDateEnd];
        const splittedDateEnd = dateEndString.split('-');
        const dateAsDateEnd = new Date(splittedDateEnd[0], splittedDateEnd[1] - 1, splittedDateEnd[2]);

        _searchData(businessId, supervisorId, employeesId, groups, dateAsDateInit, dateAsDateEnd);
    }

    const _searchData = async (businessUnits: BusinessUnitEntity[], supervisors: UserEntity[], employees: UserEntity[], groups: GroupEntity[], dateInit: Date, dateEnd: Date) => {
        const response = await di.get<GetSummaryAttendanceInTimeRangeUseCase>(GetSummaryAttendanceInTimeRangeUseCaseName).call(businessUnits, supervisors, employees, groups, dateInit, dateEnd);
        if (isLeft(response)) {
            addToast(i18n(response.left.code ?? KeyWordLocalization.UnknownError), "error", {});
            setResultData(null);
            return;
        }
        else if (!response.right.summary.some((summary) => summary.totalPlanned > 0 || summary.totalReal > 0)) {
            setResultData(null);
            return;
        }

        //sort summary by summary.total + summary.planned
        response.right.summary = response.right.summary.sort((a: SummaryTypeAttendance, b: SummaryTypeAttendance) => (b.totalPlanned + a.totalReal) - (a.totalPlanned + b.totalReal));
        response.right.summary = response.right.summary.filter((summary) => summary.totalPlanned > 0 || summary.totalReal > 0);
        setResultData(response.right);
    }

    return <div className="tracking_dashboard_page">
        <div className="container">
            <div className="card">
                <div className="card-body">
                    <SearchWithGroupsComponent defaultFormFunctions={formFunctions} isLoading={setIsLoading} itemsPerPage={20} onSearch={_handleOnChangeSearch} onSearchBusinessUnit={_searchBusinessUnit} onSearchEmployees={_searchEmployees} onSearchSupervisor={_searchSupervisor} >
                        <div className="row">
                            <div className={`form-group col-4 mt-1 ${errors[KeyWordLocalization.TrackingDashboardPageDateInit] ? 'error' : ''}`}>
                                <label>{i18n(KeyWordLocalization.TrackingDashboardPageDateInit)}</label>
                                <input type="date" className="form-control"
                                    max={new Date().toISOString().split('T')[0]}
                                    {...register(KeyWordLocalization.TrackingDashboardPageDateInit, Validators({ required: true }))} />
                                <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.TrackingDashboardPageDateInit} />
                            </div>
                            <div className={`form-group col-4 mt-1 ${errors[KeyWordLocalization.TrackingDashboardPageDateEnd] ? 'error' : ''}`}>
                                <label>{i18n(KeyWordLocalization.TrackingDashboardPageDateEnd)}</label>
                                <input type="date" className="form-control"
                                    max={new Date().toISOString().split('T')[0]}
                                    {...register(KeyWordLocalization.TrackingDashboardPageDateEnd, Validators({ required: true, greaterOrEqualThan: watch(KeyWordLocalization.TrackingDashboardPageDateInit) }))} />
                                <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.TrackingDashboardPageDateEnd} />
                            </div>
                        </div>
                    </SearchWithGroupsComponent>

                </div>
            </div>
            <div className="mt-3">
                {resultData === undefined && <LoadingComponent />}
                {resultData === null && <NotResultsComponent />}
            </div>
            {resultData !== undefined && resultData !== null && <div>
                <div className="row">
                    {resultData.summary.slice(0, 8).map((summary: SummaryTypeAttendance, index: number) => <div className='col-lg-4 col-xl-3 col-md-6 col-6 my-3' key={index}><TrackingCardResultComponent summary={summary} /> </div>)}
                </div>
                <div className="row">
                    <div className="col-12 col-md-9 my-3">
                        <div className="row">
                            <div className="my-2 col-sm-7 col-md-12">
                                <TrackingCompareLineComponent summary={resultData.summary} />
                            </div>
                            <div className="my-2 col-sm-5 col-md-12">
                                <TrackingCardTableEventCompareComponent summary={resultData.summary} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 my-3">
                        <div className="row d-flex align-items-stretch ">
                            {/* <div className="col-4 col-md-12 my-2">
                                <LineHistoricGraphComponent summary={resultData.summary} />
                            </div> */}
                            <div className="col-4 col-md-12 my-2">
                                <PieChartDashboardComponent summary={resultData.summary} title="Planned" showReal={false} />
                            </div>
                            <div className="col-4 col-md-12 my-2">
                                <PieChartDashboardComponent summary={resultData.summary} title="Real" showReal />
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    </div>
}

export default TrackingDashboardPage;
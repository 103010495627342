import { injectable } from "inversify";
import { Either } from "fp-ts/lib/Either";
import SearchUserInfoOffBoardingApiImpl from "./api/impl/SearchUserInfoOffBoardingApiImpl";
import StartOffBoardingApiImpl from "./api/impl/StartOffBoardingApiImpl";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import OffBoardingRepository, { StartTerminationApiSearchUserInfoField, SearchUserInfoResponse } from "../../../domain/repositories/OffBoardingRepository";

@injectable()
class OffBoardingRepositoryImpl implements OffBoardingRepository {
    searchUserInfo = (field: StartTerminationApiSearchUserInfoField, val: string): Promise<Either<ExceptionEntity, SearchUserInfoResponse>> => SearchUserInfoOffBoardingApiImpl(field, val);
    startTermination = (noEditable: SearchUserInfoResponse, info: { elegibleForRehire: boolean; companyPropertyReturned: string; companyPropertyNotReturned?: string; terminationPhase: string; terminationType: string; voluntaryTerminationReason?: string; jobDissatisfactionReason?: string; newOtherJobDetailedReason?: string; involuntaryTerminationReason?: string; supportingComment?: string; resignationFile?: File; }): Promise<Either<ExceptionEntity, void>> => StartOffBoardingApiImpl(noEditable, info);
}

export default OffBoardingRepositoryImpl;
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import { StartTerminationApiSearchUserInfoField, SearchUserInfoResponse } from "../../../../../domain/repositories/OffBoardingRepository";

//TODO implement
const SearchUserInfoOffBoardingApiImpl = async (field: StartTerminationApiSearchUserInfoField, val: string): Promise<Either<ExceptionEntity, SearchUserInfoResponse>> => {
    throw new Error('Not implemented');
}

export default SearchUserInfoOffBoardingApiImpl;

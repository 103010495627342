import { FC, ReactElement, RefObject, useContext, useRef, useState } from "react";
import "./AttendanceDashboardPageStyles.scss";
import { ErrorMessage } from "@hookform/error-message";
import { watch } from "fs";
import di from "../../../../di/DependencyInjection";
import BusinessUnitEntity from "../../../../domain/entities/BusinessUnitEntity";
import UserEntity from "../../../../domain/entities/UserEntity";
import SearchBusinessUnitUseCase, { SearchBusinessUnitUseCaseName } from "../../../../domain/use_cases/businessUnit/SearchBusinessUnitUseCase";
import SearchAgentsUseCase, { SearchAgentsUseCaseName } from "../../../../domain/use_cases/user/SearchAgentsUseCase";
import SearchSupervisorUseCase, { SearchSupervisorUseCaseName } from "../../../../domain/use_cases/user/SearchSupervisorUseCase";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import NotResultsComponent from "../../../components/notResults/NotResultsComponent";
import SearchWithGroupsComponent from "../../../components/searchWithGroups/SearchWithGroupsComponent";
import KeyWordLocalization from "../../../providers/localization/dictionaries/KeyWordLocalization";
import Validators from "../../../utils/Validators";
import { useForm } from "react-hook-form";
import LocalizationContext from "../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import ModalsContext from "../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";
import { GetSummaryAttendanceInTimeResponse } from "../../../../domain/repositories/TrackingTimeRepository";
import GroupEntity from "../../../../domain/entities/GroupEntity";
import AutoCompleteWithCheckComponent from "../../../components/forms/autocompleteWithCheck/AutoCompleteWithCheckComponent";
import { AutoCompleteItem } from "../../../components/forms/autocompleteWithCheck/AutoCompleteWithCheckProps";
import ButtonComponent from "../../../components/button/ButtonComponent";
import Icons from "../../../assets/Icons";
import Testing from "../../../utils/Testing";
import OverviewAttendanceDashboardComponent from "./components/overview/OverviewAttendanceDashboardComponent";
import LocationEntity from "../../../../domain/entities/LocationEntity";

interface _Option {
    label: string;
    value: string;
    ref: RefObject<{
        onSearch: (businessUnits: BusinessUnitEntity[], locations: LocationEntity[], startDate: Date, endDate: Date) => void
    }>;
}


const AttendanceDashboardPage: FC<{}> = () => {
    const formFunctions = useForm();
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const { register, getValues, setValue, handleSubmit, watch, formState: { errors }, } = formFunctions;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const ref = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [resultData, setResultData] = useState<GetSummaryAttendanceInTimeResponse | undefined | null>(null);
    const itemsPerPage = 20;
    const options = {
        overview: {
            label: 'Overview',
            value: 'overview',
            ref: useRef<{ onSearch: (businessUnits: BusinessUnitEntity[], locations: LocationEntity[], startDate: Date, endDate: Date) => void }>(null),
        },
        bu_inactive: {
            label: 'BU inactive',
            value: 'bu_inactive',
            ref: useRef<{ onSearch: (businessUnits: BusinessUnitEntity[], locations: LocationEntity[], startDate: Date, endDate: Date) => void }>(null),
        }
    }
    const [selectedOption, setSelectedOption] = useState<_Option>(options.overview);

    const _searchBusinessUnit = async (word: string): Promise<AutoCompleteItem<BusinessUnitEntity>[]> => {
        const response = await di.get<SearchBusinessUnitUseCase>(SearchBusinessUnitUseCaseName).call(word, 1, itemsPerPage, [],);
        return response.items.map((businessUnit: BusinessUnitEntity) => {
            return {
                label: businessUnit.name,
                image: businessUnit.logo,
                id: businessUnit.id,
                aditionalValue: businessUnit,
            }
        });
    }

    const _handleOnChangeSearch = async (data: any): Promise<void> => {
        console.log('searching', data);
        if (isLoading) return;
        setIsLoading(true);
        await Testing.sleeper(1000);
        setIsLoading(false);
    }

    const _handleChangeOption = (option: _Option): void => {
        setSelectedOption(option);
        const businessUnits = getValues(KeyWordLocalization.SearchWithGroupsComponentBusinessUnitIds) as BusinessUnitEntity[];
        const locations = getValues(KeyWordLocalization.SearchWithGroupsComponentBusinessUnitIds) as LocationEntity[];
        
        //date init parse
        const dateInitString = getValues()[KeyWordLocalization.TrackingDashboardPageDateInit];
        const splittedDateInit = dateInitString.split('-');
        const dateAsDateInit = new Date(splittedDateInit[0], splittedDateInit[1] - 1, splittedDateInit[2]);

        //date end parse
        const dateEndString = getValues()[KeyWordLocalization.TrackingDashboardPageDateEnd];
        const splittedDateEnd = dateEndString.split('-');
        const dateAsDateEnd = new Date(splittedDateEnd[0], splittedDateEnd[1] - 1, splittedDateEnd[2]);
        
        option.ref.current?.onSearch(businessUnits, locations, dateAsDateInit, dateAsDateEnd);
    }

    return (
        <div className="attendance_dashboard_page">
            <form onSubmit={handleSubmit(_handleOnChangeSearch)}>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-4 my-2">
                                <div className="form-group">
                                    <AutoCompleteWithCheckComponent label={i18n(KeyWordLocalization.SearchWithGroupsComponentBusinessUnitIds)}
                                        onSearch={_searchBusinessUnit}
                                        keyName={KeyWordLocalization.SearchWithGroupsComponentBusinessUnitIds}
                                        formInfo={formFunctions}
                                        multiple={true}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 my-2">
                                <div className="form-group">
                                    <AutoCompleteWithCheckComponent label={"Location"}
                                        onSearch={_searchBusinessUnit}
                                        keyName={KeyWordLocalization.SearchWithGroupsComponentBusinessUnitIds}
                                        formInfo={formFunctions}
                                        multiple={true}
                                    />
                                </div>
                            </div>
                            <div className={`form-group col-md-4 mt-1 ${errors[KeyWordLocalization.TrackingDashboardPageDateInit] ? 'error' : ''}`}>
                                <label>{i18n(KeyWordLocalization.TrackingDashboardPageDateInit)}</label>
                                <input type="date" className="form-control"
                                    max={new Date().toISOString().split('T')[0]}
                                    {...register(KeyWordLocalization.TrackingDashboardPageDateInit, Validators({ required: true }))} />
                                <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.TrackingDashboardPageDateInit} />
                            </div>
                            <div className={`form-group col-md-4 mt-1 ${errors[KeyWordLocalization.TrackingDashboardPageDateEnd] ? 'error' : ''}`}>
                                <label>{i18n(KeyWordLocalization.TrackingDashboardPageDateEnd)}</label>
                                <input type="date" className="form-control"
                                    max={new Date().toISOString().split('T')[0]}
                                    {...register(KeyWordLocalization.TrackingDashboardPageDateEnd, Validators({ required: true, greaterOrEqualThan: watch(KeyWordLocalization.TrackingDashboardPageDateInit) }))} />
                                <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.TrackingDashboardPageDateEnd} />
                            </div>
                            <div className="col-12 col-md-4" style={{ paddingTop: "1.87em" }}>
                                <ButtonComponent text="Search" typeAction="submit" onClick={() => { }} isLoading={isLoading} icon={<Icons.Search />} />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-12">
                                {Object.values(options).map((option, index) => <div className="btn btn_primary col-md-2" key={index} onClick={() => _handleChangeOption(option)}>{i18n(option.label)}</div>)}
                            </div>
                        </div>

                    </div>
                </div>

            </form>
            <div className="mt-3">
                <OverviewAttendanceDashboardComponent ref={options.overview.ref} />

            </div>
        </div>
    );
}

export default AttendanceDashboardPage;
import ShiftEntity from "../../../domain/entities/ShiftEntity";
import DateParse from "../../../ui/utils/DateParse";
import UserHostDto from "./UserHostDto";

const fromJson = (json: any): ShiftEntity => {
    const temp: ShiftEntity = {
        color: json.color,
        activity_tag: json.activity_tag,
        id: json.shift_id,
        endHour: DateParse.stringToDate(json.end_time),
        events: [],
        initHour: DateParse.stringToDate(json.start_time),
        shiftGroup: json.shift_group,
        users: json.users?.map((group: any) => UserHostDto.fromJson(group)),
        rating: json.rating ?? 0,
    }

    return temp;
}


export default {
    fromJson,
}
import { injectable } from "inversify";
import UserEntity, { UserEntityStatus } from "../../../domain/entities/UserEntity";
import UserRepository from "../../../domain/repositories/UserRepository";
import Testing from "../../../ui/utils/Testing";
import SearchResultEntity from "../../../domain/entities/SearchResultEntity";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import UpdateUserRoleIdApiImpl from "./api/impl/UpdateUserRoleIdApiImpl";
import RoleEntity from "../../../domain/entities/RoleEntity";
import SearchAllUsersApiImpl from "./api/impl/SearchAllUsersApiImpl";
import DeleteUsersApiImpl from "./api/impl/DeleteUsersApiImpl";
import SearchAllPositionsApiImpl from "./api/impl/SearchAllPositionsApiImpl";
import SearchAllBUnitsApiImpl from "./api/impl/SearchAllBUnitsApiImpl";
import SearchAllSupervisorsApiImpl from "./api/impl/SearchAllSupervisorsApiImpl";
import UpdateUsersApiImpl from "./api/impl/UpdateUsersApiImpl";
import CreateUsersApiImpl from "./api/impl/CreateUsersApiImpl";

const roleTest: RoleEntity = {
    id: '1',
    name: 'Admin',
    description: 'Module for training, this permission let the employes Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer facilisis et arcu eu fringilla. Praesent ipsum eros, maximus nec eros non, accumsan pulvinar sem. Nullam in euismod nisl. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeo ',
    modules: [],
}
export const userTest: UserEntity = {
    id: '1',
    firstname: 'Joe',
    name: 'Joe Doe',
    lastname: 'Doe',
    phone: 'phone',
    email: "joedoe@itelinternational.com",
    // enabled: true,
    status: UserEntityStatus.active,
    permissions: {
        calendar: {
            my_calendar: {
                read: true,
                create: true,
                update: true,
                delete: true
            },
            others_calendar: {
                read: true,
                create: true,
                update: true,
                delete: true
            }
        },
        prc: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        shift: {
            calendar: {
                read: true,
                create: true,
                update: true,
                delete: true
            },
            asign: {
                read: true,
                create: true,
                update: true,
                delete: true
            },
        },
        employee: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        role: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        schedule: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        shiftTemplate: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        timeTracking: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        user: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        policy: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        permission: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        attendance: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        resource: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        shiftSwap: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        aiTranscription: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        aiEvaluation: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        aiDashboard: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
    },
    image: 'https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg',
    position: 'Agent Supervisor',
    area: 'Information Technology',
    role: roleTest,
};

@injectable()
class UserRepositoryFake implements UserRepository {
    getEmployeeById = async (id: string): Promise<UserEntity | undefined> => {
        await Testing.sleeper(1000);
        return userTest;
    }
    search = async (word: string, page: number, itemsPerPage: number, filter: { [key: string]: any }): Promise<SearchResultEntity<UserEntity>> => {
        await Testing.sleeper(1000);
        return {
            items: Array(itemsPerPage).fill(userTest).map((user, index) => {
                return {
                    ...user,
                    name: `${user.name} ${index}`,
                    id: index.toString()
                }
            }),
            pages: page,
            total: itemsPerPage * 3
        }
    }
    searchSupervisor = async (word: string, page: number, itemsPerPage: number, filter: { [key: string]: any }): Promise<SearchResultEntity<UserEntity>> => {
        return {
            items: Array(itemsPerPage).fill(userTest),
            pages: page,
            total: itemsPerPage * 3
        }
    }
    searchAgents = async (word: string, page: number, itemsPerPage: number, filter: { [key: string]: any }): Promise<SearchResultEntity<UserEntity>> => {
        return {
            items: Array(itemsPerPage).fill(userTest),
            pages: page,
            total: itemsPerPage * 3
        }
    }
    updateUserRoleId = async (userId: number, roleById: number): Promise<Either<ExceptionEntity, string>> => {
        const response = await UpdateUserRoleIdApiImpl(userId, roleById);
        return response;
    }
    
    searchAllUsers = async (id: string): Promise<Either<ExceptionEntity, UserEntity[]>> => SearchAllUsersApiImpl(id);
    deleteUsers = async (users: string[]): Promise<Either<ExceptionEntity, string>> => DeleteUsersApiImpl(users);
    searchUserPositions = async (): Promise<Either<ExceptionEntity, any[]>> => SearchAllPositionsApiImpl();
    searchUserBunits = async (): Promise<Either<ExceptionEntity, any[]>> => SearchAllBUnitsApiImpl();
    searchAllSupervisors = async (): Promise<Either<ExceptionEntity, any[]>> => SearchAllSupervisorsApiImpl();
    updateUsers = async (data: any[]): Promise<Either<ExceptionEntity, string>> => UpdateUsersApiImpl(data);
    createUsers = async (data: any[]): Promise<Either<ExceptionEntity, string>> => CreateUsersApiImpl(data);
}
export default UserRepositoryFake;
import "./OverviewAttendanceDashboardComponentStyles.scss";
import { forwardRef, useImperativeHandle } from "react";
import OverviewAttendanceDashboardComponentProps from "./OverviewAttendanceDashboardComponentProps";
import BusinessUnitEntity from "../../../../../../domain/entities/BusinessUnitEntity";
import LocationEntity from "../../../../../../domain/entities/LocationEntity";

const OverviewAttendanceDashboardComponent = forwardRef((props: OverviewAttendanceDashboardComponentProps, ref) => {
    const onSearch = async (businessUnits: BusinessUnitEntity[], locations: LocationEntity[], startDate: Date, endDate: Date): Promise<void> => {
        console.log("Searching...", businessUnits, locations, startDate, endDate);
    }

    // Is accesible from the parent
    useImperativeHandle(ref, () => ({
        onSearch
    }));

    return (
        <div className="overview_attendance_dashboard_component">
            <h2>Attendance Overview</h2>
        </div>
    );
});

export default OverviewAttendanceDashboardComponent;

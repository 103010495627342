import { injectable } from "inversify";
import { userTest } from "../../../data/repositories/user/UserRepositoryFake";
import { Either, right } from "fp-ts/lib/Either";
import Testing from "../../../ui/utils/Testing";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import OffBoardingRepository, { SearchUserInfoResponse, StartTerminationApiSearchUserInfoField } from "../../../domain/repositories/OffBoardingRepository";

const startTerminationTest: SearchUserInfoResponse = {
    name: 'Joe Fired',
    hrmId: '123456',
    email: 'joe.fired@itelinternational.com',
    gender: 'male',
    birthdate: new Date('1990-01-01'),
    startDate: new Date('2019-04-01'),
    personalEmail: 'joefi@gmail.com',
    language: 'Spanish',
    employeeSupervisor: userTest,
    image: 'https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg',
    department: 'IT',
    workLocation: 'Guyana',
    location: 'On Site',
    positionAtMomentOfTermination: 'Software Developer',
}
@injectable()
export default class OffBoardingRepositoryFake implements OffBoardingRepository {
    searchUserInfo = async (field: StartTerminationApiSearchUserInfoField, val: string): Promise<Either<ExceptionEntity, SearchUserInfoResponse>> => {
        await Testing.sleeper(1000);
        return right(startTerminationTest);
    };
    startTermination = async (
        noEditable: SearchUserInfoResponse,
        info: {
            elegibleForRehire: boolean;
            companyPropertyReturned: string;
            companyPropertyNotReturned?: string;
            terminationPhase: string;
            terminationType: string;
            voluntaryTerminationReason?: string;
            jobDissatisfactionReason?: string;
            newOtherJobDetailedReason?: string;
            involuntaryTerminationReason?: string;
            supportingComment?: string; resignationFile?: File;
        }
    ) => {
        await Testing.sleeper(1000);
        return right(undefined);
    };
}
import { injectable } from "inversify";
import { Either } from "fp-ts/lib/Either";
import OffBoardingRepositoryFake from "./OffBoardingRepositoryFake";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import OffBoardingRepository, { StartTerminationApiSearchUserInfoField, SearchUserInfoResponse } from "../../../domain/repositories/OffBoardingRepository";

@injectable()
export default class OffBoardingRepositoryDev implements OffBoardingRepository {
    fakeRepository: OffBoardingRepositoryFake = new OffBoardingRepositoryFake();
    searchUserInfo = (field: StartTerminationApiSearchUserInfoField, val: string): Promise<Either<ExceptionEntity, SearchUserInfoResponse>> => this.fakeRepository.searchUserInfo(field, val);
    startTermination = (noEditable: SearchUserInfoResponse, info: { elegibleForRehire: boolean; companyPropertyReturned: string; companyPropertyNotReturned?: string; terminationPhase: string; terminationType: string; voluntaryTerminationReason?: string; jobDissatisfactionReason?: string; newOtherJobDetailedReason?: string; involuntaryTerminationReason?: string; supportingComment?: string; resignationFile?: File; }): Promise<Either<ExceptionEntity, void>> => this.fakeRepository.startTermination(noEditable, info);
}